@import url(./_colors.scss);

.naviContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

ul.react-paginate {
    margin: 0 auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    box-shadow: 0 0 3px #00000020;
    border-radius: 4px;
    padding: 0;
    max-width: 100%;

    li {
        text-align: center;
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100vw;
        max-width: 3rem;
        height: 2rem;
        box-sizing: border-box;
        border: var(--button-separate-color) 1px solid;
        border-right-width: 0;
        background-image: var(--button-search-grad);
        color: var(--text-color);
        cursor: pointer;
        user-select: none;

        &:first-child {
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
            border-right-width: 1px;
        }

        &:hover {
            background-image: var(--button-search-hover-grad);
        }

        &:has(a.active) {
            background-image: var(--phun-grad);
            border-left: none;
            cursor: default;
        }

        &:has(a.disabled) {
            cursor: default;
            background-image: var(--button-disabled-grad);
        }

        a {
            width: 100%;
            display: block;
            color: var(--text-color);
            text-align: center;

            &.active {
                color: white;
                font-weight: bold;
            }
            &.disabled {   
                color: white;
            }
        }
    }
}

@media screen and (max-width:600px) {
    ul.react-paginate {
        justify-content: space-between;
    }
}