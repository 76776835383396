@use "./colors";

$wordTypes: "noun", "prefix", "suffix", "pronoun", "affective-verb", "inaffective-verb", "special-verb", "adjective", "adverb", "particle", "quantifier", "interjection", "conjunction", "numeral", "modal", "preposition", "idiom", "morpheme";

@each $type in $wordTypes {
	.type.#{$type} {
		color: var(--#{$type});
		border-color: var(--#{$type});
	}
}

.mainContainer.dark-theme .type {
	color: white;
}